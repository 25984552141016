import React, { useRef } from 'react';

const Newsletter = () => {
  const emailInputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Your form submission logic here
    // For example, you can send the email to a server
    // and then clear the input field
    emailInputRef.current.value = '';
  };

  return (
    <div className='w-full p-4'>
      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-4'>
          <h1 className='md:text-4xl text-white txsm:text-3xl text-2xl font-bold py-2'>
            Want tips & tricks to optimize your flow?
          </h1>
          <p className='text-white'>Sign up to our newsletter and stay up to date.</p>
        </div>
        <div className='my-4'>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
              <input
                ref={emailInputRef}
                className='p-3 flex w-full rounded-md text-black border border-black'
                type='email'
                placeholder='Enter Email'
              />
              <button type='submit' className='bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3'>
                Notify Me
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
