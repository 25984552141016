import React from 'react'
import StudyAbroadBanner from './StudyAbroadBanner';
import Cards from './Cards';

import Hero from './Hero';

import Newsletter from './Newsletter';
import Contact from './Contact';
import FAQ from './FAQ';

const Home = () => {
  return (
    <div>
      <Hero />
      <StudyAbroadBanner />
      <Cards />
      <FAQ />
      <Contact />
      <Newsletter />
    </div>
  )
}

export default Home