
import React, { useRef, useState } from 'react';

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    email: '',
    message: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData(form.current);
    setMessage('Your message has been sent');
    form.current.reset(); // Reset form fields
    try {
      const response = await fetch('https://server.abenezerumeta.com/sendEmail', {
        method: 'POST',
        body: formDataToSend,
      });
      
      if (response.ok) {
        // Handle success
        
      } else {
        // Handle errors
        console.error('Email could not be sent');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='max-w-full bg-white' id='contact'>
      <div className='p-8 mx-auto max-w-2xl rounded-lg'>
        <h2 className='text-2xl font-bold mb-4 text-center'>Contact Us</h2>
        {message && <p className='text-green-500 text-center mb-4'>{message}</p>}
        <form ref={form} onSubmit={sendEmail} className='max-w-250 mx-auto'>
          <label htmlFor='name'>Name</label>
          <input
            type='text'
            id='name'
            name='user_name'
            onChange={handleChange}
            className='border rounded p-2 w-full'
          />

          <label htmlFor='email'>Email</label>
          <input
            type='email'
            id='email'
            name='email'
            onChange={handleChange}
            className='border rounded p-2 w-full'
          />

          <label htmlFor='message'>Message</label>
          <textarea
            id='message'
            name='message'
            rows='4'
            onChange={handleChange}
            className='border rounded p-2 w-full'
          ></textarea>

          <button
            type='submit'
            value='Send'
            className='bg-[#00df9a] hover:bg-[#090e2e] hover:text-white text-black font-bold py-2 px-4 rounded w-24'
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
