import React from "react";
import { Link } from 'react-router-dom';

const Register = () => {
  return (
    <div className="w-full py-6 px-4 bg-white">

      <div className="max-w-[1240px] mx-auto">
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300" style={{ minHeight: "280px", height: "500px" }}>
          <h1 className="text-xl text-center font-bold lg:pt-16">Welcome to our application process!</h1>
          <p className="text-md text-center text-bold lg:pt-12"> We offer a range of programs tailored to meet your educational goals. Whether you're a high school student looking to start your academic journey, an aspiring professional seeking a bachelor's degree, or a graduate aiming for a master's degree, we have the right program for you. Choose your desired program below to begin your application process. We look forward to helping you achieve your academic aspirations.</p>
          <div className="flex flex-col md:flex-row justify-center items-center mt-4 pt-6 lg:pt-16">
            <button className="w-full md:w-36 bg-[#21a97e] text-white px-4 py-2 rounded mb-2 md:mb-0 md:mr-2 hover:bg-[#090e2e] transition-colors duration-300"><Link to='/register_for_highschool'>High School</Link></button>
            <button className="w-full md:w-36 bg-[#21a97e] text-white px-4 py-2 rounded mb-2 md:mb-0 md:mr-2 hover:bg-[#090e2e] transition-colors duration-300"><Link to='/register_for_bachelor'>Bachelor</Link></button>
            <button className="w-full md:w-36 bg-[#21a97e] text-white px-4 py-2 rounded hover:bg-[#090e2e] transition-colors duration-300"><Link to='/register_for_masters'>Master's</Link></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;


