import React from 'react';

const About = () => {
  return (
    <div className="bg-white min-h-screen md:min-h-[80vh] py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h3 className="text-2xl font-bold leading-6 text-gray-900 text-center py-10" >About Green Travel Agency</h3>

        <div className="bg-white shadow overflow-hidden sm:rounded-lg ">
          <div className="px-4 py-5 sm:px-6 text-center">

            <p className="mt-1 max-w-2xl text-md text-gray-500 mx-auto">At Green Educational Travel Agency, we believe in the transformative power of education through travel. We specialize in creating enriching travel experiences that combine education, exploration, and adventure. </p>
            <p className="mt-1 max-w-2xl text-md text-gray-500 mx-auto">Our team is passionate about providing students and educators with opportunities to learn outside the classroom, fostering personal growth and global awareness. We work closely with schools, colleges, and educational institutions to design custom travel programs that align with curriculum goals and educational standards.</p>
            <p className="mt-1 max-w-2xl text-md text-gray-500 mx-auto"> With a focus on sustainability and responsible travel practices, we aim to minimize our environmental impact while maximizing the positive outcomes of our trips. Our carefully crafted itineraries immerse travelers in diverse cultures, environments, and communities, creating meaningful connections and lasting memories.</p>
            <p className="mt-1 max-w-2xl text-md text-gray-500 mx-auto">Join us on a journey of discovery, learning, and exploration with Green Educational Travel Agency</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
