import React from 'react';
import { RiTeamLine } from 'react-icons/ri';
import { FaRegHandshake } from 'react-icons/fa';
import { FcHighPriority } from "react-icons/fc";

const Cards = () => {
  return (
    <div className='w-full py-[5rem] px-4 bg-white'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
        <div className='w-full shadow-xl flex flex-col h-68 p-4 my-4 rounded-lg hover:scale-105 duration-300'>
          <div className='w-20 h-20 mx-auto mt-[-3rem] bg-green-500 flex items-center justify-center rounded-full text-white text-6xl'>
            <RiTeamLine />
          </div>
          <h2 className='text-2xl font-bold text-center py-8'>Experienced Team</h2>
          <p className='text-md text-center'>Our seasoned travel advisors and destination specialists bring decades of combined expertise, ensuring personalized, seamless, and memorable journeys.</p>
        </div>

        <div className='w-full shadow-xl flex flex-col h-68 p-4 my-4 rounded-lg hover:scale-105 duration-300'>
          <div className='w-20 h-20 mx-auto mt-[-3rem] bg-red-500 flex items-center justify-center rounded-full text-white text-6xl'>
          <FcHighPriority />
          </div>
          <h2 className='text-2xl font-bold text-center py-8'>Our Priority</h2>
          <p className='text-md text-center'>Explore diverse cultures, gain invaluable experiences, and broaden your horizons while we prioritize your safety, comfort, and academic goals every step of the way.".</p>
        </div>

        <div className='w-full shadow-xl flex flex-col h-64 p-4 my-4 rounded-lg hover:scale-105 duration-300'>
          <div className='w-20 h-20 mx-auto mt-[-3rem] bg-blue-500 flex items-center justify-center rounded-full text-white text-6xl'>
            <FaRegHandshake />
          </div>
          <h2 className='text-2xl font-bold text-center py-8'>Trustworthy</h2>
          <p className='text-md text-center'>Our commitment to integrity and reliability makes us a trusted partner you can rely on without hesitation.</p>
        </div>
      </div>
    </div>
  );
};

export default Cards;
