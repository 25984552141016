import React, { useState } from 'react';

const RegisterBachelorForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    matricResult: null,
    englishProficiencyLetter: null,
    internationalEnglishTest: null,
    transcript: null,
    passport: null,
    country: '',
  });

  const [submitted, setSubmitted] = useState(false); // State to manage submission status
  const [progress, setProgress] = useState(0); // State to manage progress bar

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (let key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    try {
      setProgress(0); // Reset progress
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress === 100) {
            clearInterval(interval);
            setSubmitted(true); // Set submitted to true to show the ThankYouCard
          }
          return Math.min(prevProgress + 10, 100);
        });
      }, 2000);
      const response = await fetch('https://server.abenezerumeta.com/RegisterBachelorForm/', {
        method: 'POST',
        body: formDataToSend,
      });
      if (response.ok) {
        // Handle success
        console.log('Registration successful');
      } else {
        // Handle errors
        console.error('Registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Register for Bachelor's Program</h2>
        {!submitted ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <label className="block">
            <span className="text-gray-700">Full Name:</span>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </label>

         
          <label className="block">
            <span className="text-gray-700">Phone Number:</span>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </label>
         
          <label className="block">
            <span className="text-gray-700">Email Address:</span>
            <input
              type="email"

              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Matric Result (PDF):</span>
            <input
              type="file"
              name="matricResult"
              onChange={handleFileChange}
              accept=".pdf"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">English Proficiency Letter (PDF):</span>
            <input
              type="file"
              name="englishProficiencyLetter"
              onChange={handleFileChange}
              accept=".pdf"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">International English Test(e.g IELTS,TOEFL,Duolingo) (PDF):</span>
            <input
              type="file"
              name="internationalEnglishTest"
              onChange={handleFileChange}
              accept=".pdf"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Transcript (9-12) (PDF):</span>
            <input
              type="file"
              name="transcript"
              onChange={handleFileChange}
              accept=".pdf"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Passport (PDF):</span>
            <input
              type="file"
              name="passport"
              onChange={handleFileChange}
              accept=".pdf"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Country:</span>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </label>
          <button
            type="submit"
            className="bg-[#00df9a] text-white py-2 px-4 rounded-md hover:bg-[#0d7d5a] w-full"
          >
            Submit
          </button>
        </form>
        ) : (
          <div className="text-center">
            <p className="text-green-600">Thank you for applying. After reviewing your application our team will be in touch with you in 2 days.</p>
          </div>
        )}
        {progress > 0 && progress < 100 && (
          <div className="w-full bg-gray-200 h-4 mt-4 rounded-lg relative">
            <div
              className="bg-green-500 h-full rounded-lg absolute top-0 left-0"
              style={{ width: `${progress}%` }}
            ></div>
            <p className="text-center text-xs text-gray-700 absolute w-full">Sending file</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterBachelorForm;


