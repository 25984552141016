import React, { useState } from 'react';

const FAQ = () => {
  const faqs = [
    {
      question: 'How Can I Trust Green Travel?',
      answer: "We understand that embarking on an educational journey abroad is a significant decision, and trusting your partner is essential. Here's why you can have complete confidence in choosing BlackRock are Years of Experience, Transparency, Dedicated Team, Global Network, Compliance, Client Success Stories, Financial Assistance, Accessibility.",
    },
    {
      question: 'Can You Help with Scholarships or Financial Aid?',
      answer: 'Absolutely! We believe that financial barriers should never stand in the way of your educational dreams. We are committed to helping you secure the financial support you need to turn your educational dreams into reality.',
    },
    {
      question: 'Do You Provide Services Only for Specific Countries?',
      answer: "No, we don't believe in limiting educational opportunities by borders. Our services are designed to empower you on your educational journey, regardless of your desired study destination. Here's what we offer: Global Reach, Tailored Guidance, Diverse Options, International Connections, Customized Solutions.",
    },
    {
      question: 'What are the General Application Steps?',
      answer: "Navigating the application process for your educational journey abroad may seem daunting, but we're here to simplify it for you. Here are the general application steps: Initial Consultation -> Choose Your Destination -> Select Your Program -> Prepare Required Documents -> Financial Planning -> Language Proficiency -> Submit Applications -> Prepare for Interviews -> Visa Application ->Travel and Arrival -> Orientation and Integration -> Ongoing Support. Remember, each step of the application process is manageable with the right guidance and support.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className=' text-white py-8 px-4 sm:px-8' id='FAQ'>
      <div className='max-w-3xl mx-auto'>
        <h2 className='text-3xl font-bold mb-6'>Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <div key={index} className='mb-4'>
            <div
              onClick={() => toggleFAQ(index)}
              className='cursor-pointer flex justify-between items-center border-b border-gray-300 py-4'
            >
              <p className='font-medium'>{faq.question}</p>
              <svg
                className={`w-6 h-6 ${activeIndex === index ? 'transform rotate-180' : ''}`}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <polyline points='6 9 12 15 18 9' />
              </svg>
            </div>
            {activeIndex === index && (
              <div className='py-2 px-4 text-black bg-gray-100'>
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
