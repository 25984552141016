import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import About from './components/About';
import Destination from './components/Destination';
import Home from './components/Home';
import Contact from './components/Contact';
import Register from './components/Register';
import RegisterMastersForm from './components/RegisterMastersForm';
import RegisterBachelorForm from './components/RegisterBachelorForm';
import RegisterHighSchoolForm from './components/RegisterHighSchoolForm';
import FAQ from './components/FAQ';




function App() {

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/destination" element={<Destination />} />
          <Route path="/about" element={<About />} /> 
          <Route path="/contact" element={<Contact /> } /> 
          <Route path="/faq" element={<FAQ /> } /> 
          <Route path="/register" element={<Register /> } /> 
          <Route path="/register_for_masters" element={<RegisterMastersForm /> } /> 
          <Route path="/register_for_bachelor" element={<RegisterBachelorForm /> } /> 
          <Route path="/register_for_highschool" element={<RegisterHighSchoolForm /> } /> 
         
                  
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}


export default App;
