import React from 'react';
import Laptop from '../assets/student.jpg';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const StudyAbroadBanner = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 items-center'>
        <div className='text-center md:text-left'>
      
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-4'>Study Abroad</h1>
          <p className="mb-4">
          Explore the world through our personalized study abroad programs offering full scholarships, half scholarships, and affordable prices in Italy, the UK, USA, Canada, and more. Immerse yourself in diverse cultures, broaden your horizons, and apply now for an enriching experience.
          </p>
          <div className="flex items-center justify-center md:justify-start">
            <Link className='bg-black text-[#00df9a] rounded-md font-medium my-6 md:my-0 md:mr-2 py-3 px-6 flex items-center hover:bg-gray-900 hover:text-white transition duration-300' to='/register'>
              Apply Now <FaArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
        <img className='hidden sm:block w-full md:w-[500px] mx-auto my-4 md:my-0 border-0 shadow-lg rounded-lg' src={Laptop} alt='Laptop' />
      </div>
    </div>
  );
};

export default StudyAbroadBanner;
