import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  return (

    <nav className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      <h1 className='w-full text-xl md:text-3xl font-bold text-[#00df9a]'><Link to='/'>GREEN TRAVEL AGENCY.</Link></h1>

      <ul className='hidden md:flex'>
        <li className='p-4'><Link to='/' onClick={closeNav}>Home</Link></li>
        <li className='p-4'><Link to='/destination' onClick={closeNav}>Destinations</Link></li>
        <li className='p-4'><Link to='/about' onClick={closeNav}>About</Link></li>
        <li className='p-4'><Link to='/contact' onClick={closeNav}>Contact</Link></li>
        <Link className="border border-green-500 text-xl text-white pl-4 pr-4 pt-2 pb-2 ml-7  rounded-md bg-transparent hover:bg-[#00df9a] hover:text-white transition duration-300 ease-in-out inline-block whitespace-nowrap" to="/register">Apply Now</Link>
      </ul>

      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <ul className={nav ? 'fixed left-0 top-0 w-[70%] h-full border-r border-r-gray-900 bg-[#090e2e] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
        <h1 className='w-full text-xl font-bold text-[#00df9a] m-4'><Link to='/'>GREEN TRAVEL AGENCY.</Link></h1>
        <li className='p-4 border-b border-gray-600'><Link to='/' onClick={closeNav}>Home</Link></li>
        <li className='p-4 border-b border-gray-600'><Link to='/destination' onClick={closeNav}>Destinations</Link></li>
        <li className='p-4 border-b border-gray-600'><Link to='/about' onClick={closeNav}>About</Link></li>
        <li className='p-4 border-b border-gray-600 mb-8'><Link to='/contact' onClick={ closeNav}>Contact</Link></li>
        <Link className="border border-green-500 text-white p-4 my-4 mx-8 rounded-md bg-transparent hover:bg-[#00df9a] hover:text-white transition duration-300 ease-in-out" to='/register' onClick={ closeNav}>Apply Now</Link>
      </ul>
    </nav>
  
  );
};

export default Navbar;
