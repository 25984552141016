
import React from 'react';
import ItalyFlag from '../assets/Italy.jpg';
import USAFlag from '../assets/usa.jpg';
import CanadaFlag from '../assets/canada.jpg';
import DubaiFlag from '../assets/dubai.jpg';
import UKFlag from '../assets/uk.jpg';
import AustriaFlag from '../assets/austria.jpg';
import CzechFlag from '../assets/czech.jpg';

const Destination = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8 py-5">Destination</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <div className="flex flex-col bg-white shadow-lg rounded-lg">
            <img src={ItalyFlag} alt="Italy Flag" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-bold mb-2">Italy</h3>
              <p className="text-gray-700">Italy is a country of cultural wonders, from the ancient ruins of Rome to the art treasures of Florence and the picturesque canals of Venice. Study in Italy and immerse yourself in its rich history, art, and culture.</p>
            </div>
          </div>
          <div className="flex flex-col bg-white shadow-lg rounded-lg">
            <img src={USAFlag} alt="USA Flag" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-bold mb-2">USA</h3>
              <p className="text-gray-700">The USA is a land of opportunity and diversity, with top-notch educational institutions and a rich cultural tapestry. Study in the USA and broaden your horizons while preparing for a successful career.</p>
            </div>
          </div>
          <div className="flex flex-col bg-white shadow-lg rounded-lg">
            <img src={CanadaFlag} alt="Canada Flag" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-bold mb-2">Canada</h3>
              <p className="text-gray-700">Canada offers a high-quality education system in a stunning natural environment. Study in Canada and enjoy a welcoming atmosphere, diverse culture, and ample opportunities for personal and professional growth.</p>
            </div>
          </div>
          <div className="flex flex-col bg-white shadow-lg rounded-lg">
            <img src={DubaiFlag} alt="Dubai Flag" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-bold mb-2">Dubai</h3>
              <p className="text-gray-700">Dubai is a city of contrasts, blending modern skyscrapers with ancient traditions. Study in Dubai and experience a unique mix of culture, innovation, and opportunity in one of the world's most dynamic cities.</p>
            </div>
          </div>
          <div className="flex flex-col bg-white shadow-lg rounded-lg">
            <img src={UKFlag} alt="UK Flag" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-bold mb-2">UK</h3>
              <p className="text-gray-700">The UK is renowned for its prestigious universities and rich cultural heritage. Study in the UK and immerse yourself in a diverse and vibrant academic environment while exploring the country's history and traditions.</p>
            </div>
          </div>
         
          <div className="flex flex-col bg-white shadow-lg rounded-lg">
            <img src={CzechFlag} alt="Czech Republic Flag" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-bold mb-2">Czech Republic</h3>
              <p className="text-gray-700">The Czech Republic is a country rich in history and culture, with a vibrant arts scene and stunning architecture. Study in the Czech Republic and explore its picturesque towns, medieval castles, and world-class museums.</p>
            </div>
          </div>
          <div className="flex flex-col bg-white shadow-lg rounded-lg">
            <img src={AustriaFlag} alt="Austria Flag" className="w-full h-48 object-cover rounded-t-lg" />
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-bold mb-2">Austria</h3>
              <p className="text-gray-700">Austria is a country of stunning landscapes, from the Alps to historic cities like Vienna. Study in Austria and experience its rich culture, breathtaking scenery, and world-class education.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Destination;
